import VueRouter from "vue-router";

const router = new VueRouter({
  mode: "history",
  routes: [
    // {
    //   path: "/",
    //   redirect: "/test",
    // },
    {
      path: "/login",
      component: () => import("./views/login.vue"),
    },


    {
      path: "/test",
      component: () =>
        import(/* webpackChunkName: "test" */ "./views/HelloWorld.vue"),
    },
    {
      path: "/fakbaseh5/info/:id",
      component: () => import("./views/fakbaseH5.vue"),
    },
    {
      path: "/map/msg-location",
      component: () =>
        import(/* webpackChunkName: "map" */ "./views/msgLocation.vue"),
    },
    {
      path: "/survey",
      component: () =>
        import(/* webpackChunkName: "map" */ "./views/Survey.vue"),
    },
  ],
});

export default router;
