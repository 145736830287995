<template>
  <router-view v-if="loading"></router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      loading: false,
    };
  },
  components: {},
  mounted() {
    document.getElementById("appLoading").style.display = "none";
    this.loading = true
  },
};
</script>

<style lang='scss'>
body {
  background: #fff;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}
.stat-container {
  padding-top: 20px;
}
.container {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
</style>
