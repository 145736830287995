export function getNodeId() {
  // eslint-disable-next-line
  if (typeof jsObj != "undefined") {
    // eslint-disable-next-line
    return jsObj.getNodeId();
  } else {
    return "101";
  }
}
export function getToken() {
  // eslint-disable-next-line
  if (typeof jsObj != "undefined") {
    // eslint-disable-next-line
    return jsObj.getToken();
  } else {
    return "Bearer eyJhbGciOiJIUzUxMiJ9.eyJ1c2VySWQiOiIwMDAxIiwiY2xpZW50VHlwZSI6MSwiY2xpZW50SWQiOiIxIiwidXNlck5hbWUiOiLmvJTnpLrotKblj7cxIiwic3ViIjoiMDAwMSIsImV4cCI6MTY4MjY0NzA3NH0.SDagfxi35wrOGbPL6Toe2UtPUtkP4VqlvLtgcF9LR2btUWx9wiqAXVG9-g7E5QAd0-4XoiBoVuSUhoSE824crg";
  }
}
export function getJSession() {
  // eslint-disable-next-line
  if (typeof jsObj != "undefined") {
    // eslint-disable-next-line
    return jsObj.getJSession();
  } else {
    return "88a28ef75dd04adba677103806084a6e";
  }
}

//blob 下载
export function blobDownload(blob, outName) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, outName);
  } else {
    let downloadElement = document.createElement("a");
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = outName; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放blob对象
  }
}

function cv(num) {
  return num < 10 ? "0" + num : num;
}

export function convertDtStr(DateTime) {
  let year = DateTime.getFullYear();
  let MM = cv(DateTime.getMonth() + 1);
  let day = cv(DateTime.getDate());
  let hh = cv(DateTime.getHours());
  let m = cv(DateTime.getMinutes());
  let ss = cv(DateTime.getSeconds());
  return [
    `${year}-${MM}-${day} 00:00:00`,
    `${year}-${MM}-${day} ${hh}:${m}:${ss}`,
  ];
}

export function treeToArray(arr) {
  return arr.reduce((acc, cur) => {
    if (Array.isArray(cur.children)) {
      const copyCur = { ...cur };
      delete copyCur.children;
      return acc.concat(copyCur, treeToArray(cur.children));
    } else {
      return acc.concat(cur);
    }
  }, []);
}
//获取视窗高度
export function getClientHeight() {
  let clientHeight = 0;
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight =
      document.body.clientHeight < document.documentElement.clientHeight
        ? document.body.clientHeight
        : document.documentElement.clientHeight;
  } else {
    clientHeight =
      document.body.clientHeight > document.documentElement.clientHeight
        ? document.body.clientHeight
        : document.documentElement.clientHeight;
  }
  return clientHeight;
}
export function refresh() {
  this.axios
    .get("/admin/api/weather/get", {
      params: { cityName: localStorage.getItem("cityName") },
    })
    .then((res) => {
      return res.today;
    });
}
