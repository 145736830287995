// 1. 引入
import axios from 'axios'


// 3. 配置信息
let config = {
  // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
  // baseURL: "http://localhost",
  // 请求超时时间
  timeout: 10000,
  // 每次请求携带cookie
  withCredentials: true,
  headers:{
    
  }
}
// 2. 创建实例
const instance = axios.create({...config,...axios.defaults})

instance.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    // eslint-disable-next-line
    config.headers.common['Access-Control-Allow-Origin'] = '*';
    if (config.headers && localStorage.getItem('token')) {
      config.headers.Authorization = localStorage.getItem('token')
    }
    return config
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (res) => {
    // console.log(' interceptors res', res)
    if (res.status == 200) {
      if (res.data && res.data.data) {
        // return res.data.data
        return Promise.resolve(res.data.data)
      } else {
        return Promise.resolve(res)
      }
    } else {
      return Promise.reject({})
    }
  },
  (err) => {
    return Promise.reject(err)
  }
)

// 4. 导出
export default instance
