import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
// import BaiduMap from 'vue-baidu-map'
import VueAxios from "vue-axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import router from "./router";
import axios from "./utils/request";

import { getToken, getJSession } from "./utils/utils";
Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

// eslint-disable-next-line
Vue.prototype.BMapGL = window.BMapGL;
// Vue.use(BaiduMap, {
//   ak: 'wD61TGeCvhoe9pwWO0GCNWbC3RysVIBW',
// })
Vue.prototype.$message = ElementUI.Message;

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);

// eslint-disable-next-line no-undef
router.beforeEach((to, from, next) => {
  // 先每次都去拿最新的
  // eslint-disable-next-line
  // NProgress.start()
  var token = getToken();
  // let getSession = getJSession();
  // localStorage.setItem("jsSession", getSession);
  if (token) {
    localStorage.setItem("token", token);
    next();
  } else {
    if (localStorage.getItem("token")) {
      next();
    } else {
      // localStorage.setItem("token", "wef54325234t524ytwetr");
      next();
    }
  }
  next()
});
router.afterEach((to, from) => {
  window.NProgress.done();
});

Vue.prototype.$plusExtends = fn => {
  if (window.plus) {
    setTimeout(fn, 0)
  } else {
    document.addEventListener('plusready', fn, false)
  }
}
new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
